import React, { useState } from 'react';
import { Container, Listing, ListingHeading, ViewMore } from './Hybrid.styles';
import { ProductCardWidget1 } from '../../ProductItemWidget';
import { returnCommonConfigData } from '@/utils/returnCommonConfigData';
import { Link } from '@/components/Link';
import { ITEM_ACTIVE_STATUS } from '@/utils/constants';
import { useWidgetDndContextData } from 'src/context/WidgetDndContext';
import { ProductListWrapper } from '../../Widgets2.0/ProductListWrapper';

const CategoryProductGridView = (props) => {
  const [viewMore, setViewMore] = useState(false);
  const { activeCategoryData, categoryItems, onItemsImageClick, getLink } = props;
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();
  const { infoList, isEnabled: isProductCardEnabled } = globalStyle?.productCard || {
    infoList: [],
    isEnabled: false,
  };
  const showCustomProductCard = isProductCardEnabled && infoList?.length ? true : false;

  const maxItemRender = (items) => {
    const itemsinGrid = activeCategoryData?.products_config?.max_items || 0;
    if (!viewMore && items?.length && itemsinGrid) {
      const newArr = items.slice(0, itemsinGrid);
      return newArr;
    }
    return items;
  };

  const toggleViewMore = () => {
    setViewMore((s) => !s);
  };

  const isShowViewMoreBtn = () => {
    return (
      activeCategoryData?.products_config?.max_items &&
      activeCategoryData?.products_config?.max_items < categoryItems?.length
    );
  };

  return (
    <Container padding="0">
      {props?.config?.is_show_heading && (
        <ListingHeading
          id={`listing-${activeCategoryData?.id}`}
          className="f3 fw5 mb4 tc"
          style={{
            margin: props?.config?.customStyle?.margin,
            textAlign: props?.config?.customStyle?.textAlign,
          }}
        >
          {activeCategoryData?.name ||
            activeCategoryData?.hasOwnProperty('is_custom_tag') ||
            'Available Items'}
        </ListingHeading>
      )}
      {activeCategoryData ? (
        !showCustomProductCard ? (
          <>
            <Listing
              config={returnCommonConfigData({
                activeCategoryConfig: activeCategoryData?.products_config,
                productConfig: props?.config?.product_config,
              })}
            >
              {maxItemRender(categoryItems)?.map((data) => {
                return (
                  data?.is_active === ITEM_ACTIVE_STATUS.ACTIVE && (
                    <Link href={getLink(data)} key={data.id}>
                      <ProductCardWidget1
                        data={data}
                        onItemClick={() => onItemsImageClick(data)}
                        showButton={props.showATCBtn}
                        noTags={activeCategoryData?.hasOwnProperty('is_custom_tag')}
                        categoryConfig={returnCommonConfigData({
                          activeCategoryConfig: activeCategoryData?.products_config,
                          productConfig: props?.config?.product_config,
                        })}
                        productConfig={props?.config?.product_config}
                      />
                    </Link>
                  )
                );
              })}
            </Listing>
            {isShowViewMoreBtn() ? (
              <div className="tc w-100 mt5">
                <ViewMore onClick={toggleViewMore}>
                  {viewMore ? 'View Less' : 'View More'}
                  <span />
                </ViewMore>
              </div>
            ) : null}
          </>
        ) : (
          <ProductListWrapper
            productList={maxItemRender(categoryItems)}
            customClass="tw-my-[40px]"
            calculateSelfDimensions
          >
            {isShowViewMoreBtn() ? (
              <div className="tc w-100 mt5">
                <ViewMore onClick={toggleViewMore}>
                  {viewMore ? 'View Less' : 'View More'}
                  <span />
                </ViewMore>
              </div>
            ) : null}
          </ProductListWrapper>
        )
      ) : null}
    </Container>
  );
};

export default CategoryProductGridView;

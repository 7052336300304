import styled from 'styled-components';

export const Container = styled.article`
  padding: ${({ padding }) => padding || '4rem 0 2rem'};
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: auto;
  box-sizing: border-box;
  position: relative;
`;

export const Heading = styled.h3`
  text-align: center;
  width: 100%;
  font-size: 40px;
  color: ${(props) => props.fontColor || '#000000'};

  @media screen and (min-width: 767px) {
    font-size: 40px;
    margin-bottom: 3rem;
  }

  @media screen and (max-width: 766px) {
    font-size: 28px;
    margin-bottom: 2rem;
  }
`;

export const Listing = styled.div`
  width: 100%;
  max-width: 1000px;
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  box-sizing: border-box;
  margin-bottom: 3rem;
  margin: 0 auto;

  @media screen and (min-width: 767px) {
    grid-template-columns: repeat(
      ${(props) => props.config?.desktop?.grid?.cols || 3},
      minmax(0, 1fr)
    );
    row-gap: ${(props) => `${props.config?.desktop?.spacing?.row || 60}px`};
    column-gap: ${(props) => `${props.config?.desktop?.spacing?.col || 24}px`};
    padding: 0 2rem;
  }

  @media screen and (max-width: 766px) {
    grid-template-columns: repeat(
      ${(props) => props.config?.mobile?.grid?.cols || 2},
      minmax(0, 1fr)
    );
    row-gap: ${(props) => `${props.config?.mobile?.spacing?.row || 24}px`};
    column-gap: ${(props) => `${props.config?.mobile?.spacing?.col || 16}px`};
    padding: 0 1rem;
  }
`;

export const ListingHeading = styled.h2`
  color: #000000;
  font-weight: 500;
  margin-bottom: 2rem;
  text-align: center;

  @media screen and (min-width: 767px) {
    font-size: 24px;
    padding: 0 2rem;
  }

  @media screen and (max-width: 766px) {
    font-size: 24px;
    padding: 0 1rem;
  }
`;

export const AllCategorySection = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  position: sticky;
  bottom: 5%;
  z-index: 50;
  background: transparent;
  width: 100%;
`;

export const AllCategoryButton = styled.div`
  text-align: center;
  border: 2px solid #ffffff;
  border-radius: 30px;
  box-shadow: 0px 16px 36px #0000004a;
  background: ${(props) => props.backgroundColor || '#ffffff'} 0% 0% no-repeat padding-box;
  color: ${(props) => (props.backgroundColor ? '#ffffff' : '#000000')};
  cursor: pointer;

  @media screen and (min-width: 767px) {
    font-size: 18px;
    padding: 16px 40px;
  }

  @media screen and (max-width: 766px) {
    font-size: 14px;
    padding: 14px 16px;
  }
`;

export const ViewMore = styled.span`
  position: relative;
  margin: 0 auto;
  color: #6f6e6e;
  font-size: 22px;
  letter-spacing: 0;
  cursor: pointer;
  & > span {
    position: absolute;
    bottom: -4px;
    left: 0;
    background-color: #000000;
    width: 0;
    height: 1px;
    transition: width ease-in-out 300ms;
  }
  &:hover {
    color: #000000;
    & > span {
      width: 100%;
    }
  }
`;
